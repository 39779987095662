import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/friday-the-13th-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, Friday the 13th"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 21</h1>
                    <h2>Friday the 13th</h2>
                    <h3>October 13, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Sean Cunningham // <b>Starring:</b> Adrienne King &amp; Betsy Palmer</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/21-Friday-the-13th-Bonus-e2ag3kh" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast episode 21, Friday the 13th"></iframe>
                        </div>
                        <p><Link to="/transcripts/friday-the-13th">Read the episode transcript</Link></p>
                        <p>Bryan and Dave square off against one of the all-time horror movie greats on the most appropriate calendar date of the year. It's 1980's Friday the 13th, a movie made by pornography producers looking to take away a little of that sweet Halloween money for themselves and accidentally set the pace for slasher movies in the 80's. If you had a little cash on-hand with which to make a movie, you set it at a summer camp and killed off a bunch of teenagers for maximum return based on the least possible effort.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=aDrOvFtzyPQ" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=aDrOvFtzyPQ</OutboundLink>⁠</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/halloween-2007">Next Episode</Link></li>
                        <li><Link to="/episodes/halloween-2018">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)